import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b106f6ae"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "text"
};
const _hoisted_2 = ["value"];
const _hoisted_3 = ["value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  return _openBlock(), _createElementBlock("div", {
    class: "selector",
    style: _normalizeStyle({
      width: $props.width
    })
  }, [_createElementVNode("div", _hoisted_1, _toDisplayString($options.showValue || $props.title), 1), _createVNode(_component_van_icon, {
    name: "arrow-down",
    color: "#ccc",
    style: {
      "margin-left": "0.1rem"
    }
  }), _createElementVNode("select", {
    class: "select",
    value: $props.value,
    onChange: _cache[0] || (_cache[0] = (...args) => $options.handleChange && $options.handleChange(...args))
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, item => {
    return _openBlock(), _createElementBlock("option", {
      key: item.value,
      value: item.value
    }, _toDisplayString(item.label), 9, _hoisted_3);
  }), 128))], 40, _hoisted_2)], 4);
}