import "vant/es/icon/style";
import _Icon from "vant/es/icon";
export default {
  name: 'Selector',
  props: ['value', 'title', 'options', 'width'],
  components: {
    [_Icon.name]: _Icon
    // VanPicker: Picker
  },

  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleChange(e) {
      this.$emit('update:value', e.target.value);
    }
  },
  computed: {
    showValue() {
      if (this.value) {
        return this.options.find(item => item.value == this.value).label;
      }
      return '';
    }
  }
};